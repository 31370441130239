export default[
  
  {
    path: "/login",
    name: "login",
    meta:{
      title: '登录页',
      auth: true,
    },
    component: () => import("../../views/login.vue")
  }
]
