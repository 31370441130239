import factory from "./factory";
import hazardous from "./hazardous";
import { getItem, setItem } from '../../assets/js/storage.js';

let url = '/hazardous';
const sideBarNav = getItem('sideBar');
if (sideBarNav && sideBarNav.SW != undefined && sideBarNav.SW.length) {
  url = '/factory'
} 
export default[
  {
    path: "/",
    name: "page",
    meta:{
      title: '顶层',
      scroll: true
    },
    component: () => import("../../views/index.vue"),
    redirect: url,
    children: [
      ...factory,
      ...hazardous
    ]
  }
]
