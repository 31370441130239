import { baseUrl } from '../api';
import { getItem } from '../assets/js/storage.js';
import SockJS from 'sockjs-client/dist/sockjs.min.js';
import Stomp from 'stompjs';
import store from '../stores';

var stompClient  = null;
export default {
  // websocket建立链接
  global_websocket_connect() {
    var s = new SockJS(`${baseUrl}endpoint`);
    stompClient = Stomp.over(s);
    stompClient.heartbeat.outgoing = 10000;
    stompClient.heartbeat.incoming = 10000;
    stompClient.debug = () => {};
    stompClient.connect({}, () => {
      this.connectCallback();
    }, () => {
      this.errorCallback()
    });
    
  },
  // 接收订阅消息
  connectCallback() {
    if (stompClient  != null) {
      var setUrl = getItem('socket');
      // console.log(stompClient);
      // console.log(setUrl);
      stompClient.subscribe(setUrl, res => {
        // console.log(setUrl);
        // console.log(JSON.parse(res.body));
        const result = JSON.parse(res.body);
        // console.log(result);
        var $store = store.socketFun();
        $store.setVuexSocket(result);
      })
    } else {
      console.log('等待连接中')
    }
  },
  errorCallback(){
    if(stompClient != null && stompClient.connected) return;
    // console.log(this);
    this.global_websocket_connect();
  },
}