import { defineStore } from 'pinia'

export const socketFun = defineStore('socketFun', {
  state: () => {
    return{
      vuexSocket: ''
    }
  },
  actions: {
    setVuexSocket(data){
      this.vuexSocket = data;
    }
  }
})