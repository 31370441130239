import { defineStore } from 'pinia';
import { getItem } from '../assets/js/storage.js';

export const tool = defineStore('tool', {
  
  state: () => {
    return {
      codeValue: 0,
      deviceTempId: 0,
      routeList: [],
      sideBar: getItem('sideBar'),
      sidetag: getItem('sidetag'),
      sideView: getItem('sideView'),
      userInfo: getItem('userInfo'),
      tableInfo: getItem('tableInfo'),
      wasteType: getItem('wasteType'),
      scrollValue: '',
      swiperTime: '',
      directoryId: ''
    }
  },
  actions: {
    setCodeValue(data) {
      this.codeValue = data;
    },
    setDeviceTempId(data) {
      this.deviceTempId = data;
    },
    setRouteList(data) {
      this.routeList = data;
    },
    setSideBar(data) {
      this.sideBar = data;
    },
    setSidetag(data) {
      this.sidetag = data;
    },
    setSideView(data){
      this.sideView = data;
    },
    setUserInfo(data) {
      this.userInfo = data;
    },
    setTableInfo(data) {
      this.tableInfo = data;
    },
    setWasteType(data) {
      this.wasteType = data;
    },
    setScrollValue(data){
      this.scrollValue = data;
    },
    setSwiperTime(data){
      this.swiperTime = data ? data : 3000
    },
    setDirectory(data) {
      this.directoryId = data;
    }
  },
  getters:{
    getHeight: (state) => {
      // return state.data.height;
    }
  }
})