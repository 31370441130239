import { defineStore } from 'pinia'

export const formData = defineStore('formData', {
  state: () => {
    return {
      content: {}
    }
  },
  actions: {
    setData(data) {
      this.content = data;
    },
  },
  getters:{
    getData: (state) => {
      return state.content;
    }
  },
})
