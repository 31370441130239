/*
 * @Author: 569353634@qq.com
 * @Date: 2022-08-02 15:52:56
 * @LastEditors: i4uutui@163.com
 * @LastEditTime: 2023-02-14 17:08:44
 */
const setting = {
  amapKey: '6ef0e4f65df5233b3473b7cfcf74b33b', // 地图key
  amapJsCode: 'c750d3ce7a81492b54008a2c9364b2e9' ,// 地图安全密钥
  appid: 2,
  deviceTypeId: 'devicePart',
}

export default setting