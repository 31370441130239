/*
 * @Author: 569353634@qq.com
 * @Date: 2022-11-25 10:36:54
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-06 17:50:52
 */
import { formData } from "./formData"
import { tool } from "./tool"
import { socketFun } from "./socket"
export default{
  formData,
  tool,
  socketFun
}