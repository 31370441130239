import timesFormats from './timeFormat.js';
import settings from './setting.js';
import tests from './test.js';
import deepClones from './deepClone.js';
import { setNumber, plus } from './digit';

export const test = tests;
export const setting = settings;
export const timesFormat = timesFormats;
export const deepClone = deepClones;
export const deepArr = (arr) => {
  var list = [];
  arr.forEach(item => {
    list.push(item);
  })
  return list;
}
export const getNumber = (num, fixed) => {//修改js四舍五入
  if(num){
    var pos = num.toString().indexOf('.'),
    decimal_places = num.toString().length - pos - 1,
    _int = num * Math.pow(10, decimal_places),
    divisor_1 = Math.pow(10, decimal_places - fixed),
    divisor_2 = Math.pow(10, fixed);
    return Math.round(_int / divisor_1) / divisor_2;
  }else{
    return null;
  }
}
// 判断不能为空，但可以为0
export const checkValue = (value) => {
  return !(!value && String(value) !== '0');
}
// 判断对象是否为空
export const checkObj = (obj) => {
  for(var key in obj) {
    return false;
  }
  return true;
}
// 是否是对象
export const checkObject = (value) => {
  return Object.prototype.toString.call(value) === '[object Object]'
}
export const setValue = (row, arr) => {
	var str = '';
	arr.some(e => {
		if (row[e]) {
			str = row[e];
			return true;
		}
	});
	return str;
}

// 节流
let timer = null
export const throttle = (fn, delay = 300) => {
  if(timer == null){
    timer = setTimeout(() => {
      fn()

      clearTimeout(timer)
      timer = null
    }, delay);
  }
}
// 处理数据字典的数据
export const setForDic = (data) => {
  let obj = {};
  for (const key in data) {
    obj[data[key].code] = data[key].name;
  }
  return obj;
}
/***
 * @description: 数组分组
 * @param {Array} arr 传入一组数组
 * @param {Number} size 传入数字,每组多少个
 * @return {Array} 返回已分好的数组
 */
export const chunk = (arr, size) => {
	var result = [];
	let len = arr.length;
	for (var i = 0; i < len; i += size) {
		result.push(arr.slice(i, i + size));
	}
	return result;
}
/**
 * @description 数字格式化
 * @param {number|string} number 要格式化的数字
 * @param {number} decimals 保留几位小数
 * @param {string} decimalPoint 小数点符号
 * @param {string} thousandsSeparator 千分位符号
 * @returns {string} 格式化后的数字
 */
export const priceFormat = (number, decimals, decimalPoint = '.', thousandsSeparator = ',') => {
	number = (`${number}`).replace(/[^0-9+-Ee.]/g, '')
	const n = !isFinite(+number) ? 0 : +number
	const prec = !isFinite(+decimals) ? '' : Math.abs(decimals)
	const sep = (typeof thousandsSeparator === 'undefined') ? ',' : thousandsSeparator
	const dec = (typeof decimalPoint === 'undefined') ? '.' : decimalPoint
	let s = ''
  
  const last = number.split('.')[1];
  const num = (prec ? setNumber(n, prec) + '' : last ? setNumber(n, last.length) : `${Math.round(n)}`).toString();
	s = num.split('.')
  
	const re = /(-?\d+)(\d{3})/
	while (re.test(s[0])) {
		s[0] = s[0].replace(re, `$1${sep}$2`)
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || ''
		s[1] += new Array(prec - s[1].length + 1).join('0')
	}
	return s.join(dec)
}
/**
 * @description 日期大小比较
 * @param {string} date1 第一个日期
 * @param {string} date2 第二个日期
 * @returns {string} -1：date1小于date2；1：date1大于date2；0：日期相等
 */
export function compareDates(date1, date2) {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  if (firstDate < secondDate) {
    return -1; // date1 小于 date2
  } else if (firstDate > secondDate) {
    return 1; // date1 大于 date2
  } else {
    return 0; // 日期相等
  }
}

// 合并多单位数量，多单位显示
export const aggregateArray = (options, field) => {
  const result = []
  // const data = arr ?? options.map(item => item.id)
  const dealData = options.reduce((allValues, currItem) => {
    // const currItem = options.find(item => item.id === id)
    const curr = allValues[currItem.unit] ?? 0
    return {
      ...allValues,
      [currItem.unit]: plus(curr, parseFloat(currItem[field]))
    }
  }, {})
  for (const unit in dealData) {
    if (dealData.hasOwnProperty(unit)) {
      result.push({ count: dealData[unit], unit });
    }
  }
  return result.sort((a, b) => a.unit.localeCompare(b.unit));
}

export const downloadFile = (fileName, urlData) => {
  const element = document.createElement('a');
  element.setAttribute('href', urlData);
  element.setAttribute('download', fileName);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const getDistanceToParent = (element) => {
  var parent = element.parentNode;
  var parentRect = parent.getBoundingClientRect();
  var childRect = element.getBoundingClientRect();
  var distance = childRect.top - parentRect.top;
  return distance;
}